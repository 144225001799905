import * as Sentry from '@sentry/browser';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import ConfigApi from '../config/api';
import { buildConfig, setConfig as setDeprecatedConfig, setLanguage } from '../config/config';
import { AppConfigType, BackendConfigType } from '../config/types';
import AppRoutes from '../routes';
import useFetch from '../utils/hooks/useFetch';
import { AppConfigContext } from './AppConfigContext';
import GlobalError from './GlobalError';
import GlobalLoader from './GlobalLoader';

const App: FC = () => {
    const [fetch, loading, error] = useFetch(ConfigApi.getConfig);
    const [config, setConfig] = useState<AppConfigType>();

    useEffect(() => {
        fetch().then((resp) => {
            const cfg = buildConfig(resp as BackendConfigType);
            cfg.setConfig = (remote) => {
                //@ts-ignore
                setConfig({ ...config, remote });
            };
            setConfig(cfg);
            setDeprecatedConfig(cfg);
        });
    }, [fetch]);

    if (loading || config === undefined) {
        return <GlobalLoader />;
    }

    if (error) {
        return <GlobalError />;
    }

    Sentry.setContext('config', config);
    const momentLocale = config?.remote.language.split('_')[0] ?? 'ru';
    setLanguage(momentLocale); //Hack for old translate function

    dayjs.extend(require('dayjs/plugin/relativeTime'));
    require('dayjs/locale/' + momentLocale + '.js');
    dayjs.locale(momentLocale);
    const antdLocale = require('antd/es/locale/' + config.remote.language + '.js').default;

    dayjs.extend((option, dayjsClass, dayjsFactory) => {
        dayjsClass.prototype.toJSON = function () {
            return this.format(config.remote.tenantConfig.formats.dateTime);
        };
    });

    return (
        <AppConfigContext.Provider value={config}>
            <ConfigProvider locale={antdLocale}>
                <AppRoutes />
            </ConfigProvider>
        </AppConfigContext.Provider>
    );
};

export default App;
