export default {
    byRange: {
        ru: 'За период',
        en: 'By range',
    },
    byMonth: {
        ru: 'По месяцам',
        en: 'By month',
    },
    manager: {
        ru: 'Менеджер',
        en: 'Manager',
    },
    count: {
        ru: 'Количество',
        en: 'Count',
    },
    total: {
        ru: 'Сумма',
        en: 'Total',
    },
    average: {
        ru: 'Средний чек',
        en: 'Average',
    },
    ranges: {
        overall: {
            ru: 'Всё время',
            en: 'Overall',
        },
        thisMonth: {
            ru: 'Текущий месяц',
            en: 'Current month',
        },
        nextMonth: {
            ru: 'Следующий месяц',
            en: 'Next month',
        },
        previousMonth: {
            ru: 'Прошлый месяц',
            en: 'Previous month',
        },
        thisYear: {
            ru: 'Текущий год',
            en: 'Current year',
        },
        previousYear: {
            ru: 'Прошлый год',
            en: 'Previous year',
        },
        thisWeek: {
            en: 'This week',
            ru: 'Эта неделя',
        },
        today: {
            en: 'Today',
            ru: 'Сегодня',
        },
    },
    views: {
        chart: {
            ru: 'График',
            en: 'Chart',
        },
        table: {
            ru: 'Таблица',
            en: 'Table',
        },
    },
};
