import { AxiosResponse } from 'axios';
import { DetailedTask } from 'modules/tasks/types';
import { ApiResponseWithPagination } from 'types/apiTypes';
import { DateRangeType } from 'types/common';
import { ScopeGroupType } from 'types/scopes';
import getPagination from 'utils/api/pagination';
import Http from 'utils/Http';
import { Notification } from './types';

export default class NotificationsApi {
    static search: (
        page: number,
        scope?: string,
        search?: string,
        dateRange?: DateRangeType,
        sort?: string
    ) => Promise<ApiResponseWithPagination<Notification[]>> = async (
        page,
        scopes,
        search,
        dateRange,
        sort
    ) => {
        const response: AxiosResponse<Notification[]> = await Http.get('/notifications', {
            params: {
                scopes,
                search,
                page,
                from: dateRange?.from.format('YYYY-MM-DD'),
                to: dateRange?.to.format('YYYY-MM-DD'),
                sort,
            },
        });
        return {
            data: response.data,
            pagination: getPagination(response),
        };
    };

    static fetchScopes: () => Promise<ScopeGroupType[]> = async () => {
        const response: AxiosResponse<ScopeGroupType[]> = await Http.get('/notifications/scopes');
        return response.data;
    };

    static markAsRead: (ids: string[]) => Promise<DetailedTask> = async (ids) => {
        const response: AxiosResponse<DetailedTask> = await Http.post(`/notifications/read`, {
            ids,
        });
        return response.data;
    };
}
