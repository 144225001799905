import Model from 'utils/Model';
import validators from 'utils/validators';
import { composeValidators } from 'utils/validators/compose';
import PaymentModel from '../../../contractors/models/payments/PaymentModel';
import OrderModel from '../OrderModel';

class OrderPaymentModel extends Model {
    static validators = {
        number: composeValidators(validators.required, validators.minLength(3)),
        date: composeValidators(validators.required, validators.date),
        value: composeValidators(validators.required, validators.number, validators.minNumber(0)),
        description: composeValidators(validators.maxLength(255)),
    };

    initialize(props) {
        super.initialize(props);
        this.orderId = props.orderId;
        this.contractorPaymentId = props.contractorPaymentId || null;
        this.contractorPayment = new PaymentModel(props.contractorPayment);
        this.order = new OrderModel(props.order);
        this.value = props.value || null;
        this.valueFormatted = props.valueFormatted || null;
    }
}

export default OrderPaymentModel;
