import { useCallback, useDebugValue, useState } from 'react';

type FetcherType<P extends any[], T> = (...params: P) => Promise<T>;
export default function useFetch<P extends any[], T>(
    fetcher: FetcherType<P, T>,
    defaultLoading: boolean = false,
    doThrow: boolean = true
): [FetcherType<P, T>, boolean, Error | null] {
    const [loading, setLoading] = useState<boolean>(defaultLoading);
    const [error, setError] = useState<Error | null>(null);

    useDebugValue({ loading, error });

    const fetch: FetcherType<P, T> = async (...params) => {
        setLoading(true);
        setError(null);
        try {
            let fetched = await fetcher(...params);
            setLoading(false);
            setError(null);
            return fetched as T;
        } catch (err) {
            setError(err);
            setLoading(false);
            if (doThrow) {
                throw err;
            }
        }
        return {} as T;
    };

    return [useCallback(fetch, []), loading, error];
}
