import { message, notification } from 'antd';
import { Centrifuge } from 'centrifuge';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../common/ui/markup/Button';
import useConfig from '../../../../utils/hooks/useConfig';
import WebSocketApi from '../api';
import { WebSocketNotificationContext } from '../types';

const WebSocketSubscriber = () => {
    const config = useConfig();
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    let centrifuge: Centrifuge | null = null;

    useEffect(() => {
        if (!config.remote.centrifugo.enabled) {
            return;
        }
        const init = async () => {
            if (!config.remote.centrifugo.endpoint) {
                message.error('WebSocket endpoint is not configured');
                return;
            }

            centrifuge = new Centrifuge(config.remote.centrifugo.endpoint, {
                getToken: async () => {
                    return await WebSocketApi.getConnectionToken();
                },
            });

            centrifuge.on('connected', async function (ctx) {
                if (centrifuge === null) {
                    return;
                }
                const channels = await WebSocketApi.getSubscriptionTokens();

                for (const channel in channels) {
                    const sub = centrifuge.newSubscription(channel, {
                        token: channels[channel],
                    });

                    sub.on('publication', function (ctx: WebSocketNotificationContext) {
                        if (ctx.data.type === 'visible') {
                            const notification = ctx.data.notification;

                            const btn = (
                                <>
                                    <Button
                                        primary
                                        small
                                        className={'mt-2 mb-0 me-0'}
                                        onClick={() => {
                                            api.destroy(notification.id);
                                            navigate(notification.cta.url);
                                        }}
                                    >
                                        {notification.cta.title}
                                    </Button>
                                </>
                            );

                            api.open({
                                message: notification.title,
                                description: notification.text,
                                duration: notification.ttl,
                                showProgress: true,
                                btn,
                                key: notification.id,
                            });
                        }

                        if (ctx.data.badge !== undefined) {
                            config.remote.notifications.badge = ctx.data.badge;
                            config.setConfig(config.remote);
                        }
                    });

                    sub.subscribe();
                }
            });

            centrifuge.connect();
        };

        init();

        return () => {
            if (centrifuge) {
                console.log('disconnect Centrifuge');
                centrifuge.disconnect();
            }
        };
    }, []);

    return <>{contextHolder}</>;
};

export default WebSocketSubscriber;
