import { AxiosResponse } from 'axios';
import { PaginationData } from '../../types/apiTypes';

export default function getPagination(response: AxiosResponse): PaginationData {
    return {
        page: parseInt(response.headers['x-pagination-current-page'] ?? 0),
        pageCount: parseInt(response.headers['x-pagination-page-count'] ?? 0),
        perPage: parseInt(response.headers['x-pagination-per-page'] ?? 0),
        totalCount: parseInt(response.headers['x-pagination-total-count'] ?? 0),
    };
}
