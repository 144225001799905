import NotificationsApi from 'modules/notifications/api';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, guard }) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const unid = searchParams.get('track_notification');
        if (unid) {
            searchParams.delete('track_notification');
            setTimeout(() => {
                NotificationsApi.markAsRead([unid]).catch(console.error);
            }, 0);
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    if (!isAuthenticated) {
        sessionStorage.setItem('afterLoginRedirect', location.pathname);
        return <Navigate to={`/login?from=${location.pathname}`} />;
    }

    return <Outlet />;
};

function mapStateToProps(store) {
    return {
        isAuthenticated: store.auth.isAuthenticated,
    };
}

export default connect(mapStateToProps)(PrivateRoute);
