import { NeedCatalogsManage, NeedCatalogsView } from 'common/core/access-control/guards/catalogs';
import { NeedCommonSpecificationView } from 'common/core/access-control/guards/common';
import { NeedContractorsListView } from 'common/core/access-control/guards/contractors';
import { NeedLicenseView } from 'common/core/access-control/guards/license';
import {
    NeedOrdersListView,
    NeedOrdersListViewOwn,
    NeedOrdersRealisationsView,
} from 'common/core/access-control/guards/orders';
import { NeedProjectsListView } from 'common/core/access-control/guards/projects';
import { NeedRegionsView } from 'common/core/access-control/guards/regions';
import { NeedStructureListView } from 'common/core/access-control/guards/structure';
import { NeedTasksView } from 'common/core/access-control/guards/tasks';
import { NeedUsersListView } from 'common/core/access-control/guards/users';
import { t } from 'translations';

export default function getItems(config) {
    return [
        {
            caption: t('navigation.dashboard'),
            icon: 'feather icon-pie-chart',
            link: '/',
            exact: true,
        },
        {
            caption: t('navigation.notifications'),
            icon: 'feather icon-bell',
            link: '/notifications/inbox',
            badge: config.remote.notifications.badge,
            exact: true,
        },
        {
            caption: t('navigation.users'),
            icon: 'feather icon-users',
            link: '/users',
            guard: NeedUsersListView,
            isActive: (match, location) => {
                return location.pathname.startsWith('/user');
            },
            // items: [
            //   {
            //     caption: 'Список',
            //     link: '/users',
            //     exact: true,
            //   },
            // ]
        },
        {
            caption: t('navigation.contractors'),
            icon: 'feather icon-link',
            link: '/contractors',
            guard: NeedContractorsListView,
            isActive: (match, location) => {
                return location.pathname.startsWith('/contractor');
            },
        },
        {
            caption: t('navigation.projects'),
            icon: 'feather icon-layers',
            link: '/projects',
            guard: NeedProjectsListView,
            visible: config.remote.projects.enabled,
            isActive: (match, location) => {
                return location.pathname.startsWith('/project');
            },
        },
        {
            caption: t('navigation.orders'),
            icon: 'feather icon-box',
            link: '/orders',
            guard: NeedOrdersListViewOwn,
            visible: true,
            exact: true,
            isActive: (match, location) => {
                return location.pathname.startsWith('/order');
            },
        },
        {
            caption: t('navigation.orders'),
            icon: 'feather icon-box',
            guard: NeedOrdersListViewOwn,
            visible: false,
            items: [
                {
                    caption: t('navigation.ordersList'),
                    link: '/orders',
                    exact: true,
                    guard: NeedOrdersListViewOwn,
                    isActive: (match, location) => {
                        return (
                            location.pathname.startsWith('/order/') ||
                            location.pathname === '/orders'
                        );
                    },
                },
                {
                    caption: t('navigation.ordersRealisations'),
                    link: '/orders/realisations',
                    guard: NeedOrdersRealisationsView,
                    exact: true,
                },
            ],
        },
        {
            caption: t('tasks.tasks'),
            icon: 'feather icon-inbox',
            link: '/tasks',
            guard: NeedTasksView,
            visible: config.remote.tasks.enabled,
            isActive: (match, location) => {
                return location.pathname.startsWith('/task');
            },
        },

        {
            caption: t('navigation.structure'),
            icon: 'feather icon-share-2',
            link: '/structure',
            guard: NeedStructureListView,
            visible: config.remote.structure.enabled,
            items: [
                {
                    caption: t('navigation.structureList'),
                    link: '/structure',
                    exact: true,
                    guard: NeedOrdersListView,
                    isActive: (match, location) => {
                        return (
                            (location.pathname.startsWith('/structure/') ||
                                location.pathname === '/structure') &&
                            !location.pathname.startsWith('/structure/tree')
                        );
                    },
                },
                {
                    caption: t('navigation.structureTree'),
                    link: '/structure/tree',
                    exact: true,
                    guard: NeedOrdersListView,
                },
                {
                    caption: t('navigation.regions'),
                    link: '/regions',
                    exact: true,
                    visible: config.remote.regions.enabled,
                    guard: NeedRegionsView,
                },
            ],
        },
        {
            caption: t('navigation.catalogs'),
            icon: 'feather icon-folder',
            guard: NeedCatalogsView,
            visible: config.remote.catalogs.enabled,
            items: [
                {
                    caption: t('navigation.myCatalogs'),
                    link: '/catalogs/my',
                    guard: NeedCatalogsView,
                    visible: config.remote.catalogs.enabled,
                    isActive: (match, location) => {
                        return location.pathname.startsWith('/catalogs/my');
                    },
                },
                {
                    caption: t('navigation.management'),
                    link: '/catalogs/manage',
                    guard: NeedCatalogsManage,
                    visible: config.remote.catalogs.enabled,
                    isActive: (match, location) => {
                        return location.pathname.startsWith('/catalogs/manage');
                    },
                },
            ],
        },
        {
            caption: t('navigation.apiSpec'),
            icon: 'feather icon-slack',
            link: '/exchange',
            guard: NeedCommonSpecificationView,
            visible: false,
            isActive: (match, location) => {
                return location.pathname.startsWith('/exchange');
            },
        },
        {
            caption: t('navigation.license'),
            icon: 'feather icon-shield',
            link: '/license',
            guard: NeedLicenseView,
            visible: false,
            isActive: (match, location) => {
                return location.pathname.startsWith('/license');
            },
        },
    ];
}
